// Initializing the EmailJs libary
(function() {
    emailjs.init('fuzcLgUOsi8zZRCNV');
})();


//  Getting elements
const form = document.querySelector('.contact__form');
const userName = document.getElementById('name');
const userEmail = document.getElementById('email');
const userMessage = document.getElementById('message');
// const messageContainer = document.querySelector('.message-container');
// const message = document.getElementById('message');

let isValid = false


    function validateForm() {
        // Using Contraint API
        isValid = form.checkValidity();
        // Style main message for an error
        // if(isValid === false) {
        //     message.textContent ="Please fil out all the fields";
        //     message.style.color = 'red';
        //     messageContainer.style.borderColor = 'red';
        // } else if(isValid === true) {
        //     message.textContent ="Your message is being sent";
        //     message.style.color = 'Orange';
        //     messageContainer.style.borderColor = 'Orange';
        // }
    }

     function processFormData(e) {
         e.preventDefault()
         // Validating form
         validateForm();

         const templateParams = {
             name: userName.value,
             email: userEmail.value,
             message: userMessage.value,
         }


        // Sending the formdata
        if(isValid === true) {
        emailjs.send('service_6qy6yj8', 'contact_template_p00nerg', templateParams)
        .then(function(){
            alert(`Hartelijk dank ${userName.value} ik neem zo snel mogelijk contact met u op.`)
            userName.value = '';
            userEmail.value = '';
            userMessage.value = '';
        }, function(error){
            console.log("FAILED", error);
        })
        }
     }

     //  Add eventlisteners
    form.addEventListener('submit', processFormData);
