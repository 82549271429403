import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import contactForm from "./scripts/contactForm";
import toggleNavBar  from "./scripts/toggleNavMenu";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";
import {Cloudinary} from "@cloudinary/url-gen";
import { cleanData } from "jquery";


initScrollReveal(targetElements, defaultProps);
initTiltEffect();
contactForm;

// Selectors
const skillsFrondEnd = document.getElementById('front-end-skills');
const skillsTools = document.getElementById('tool-skills');
const skillsBackEnd = document.getElementById('back-end-skills');
const frontEndInfo = document.querySelector('.skill-icons--front-end')
const toolsInfo = document.querySelector('.skill-icons--tools')
const backEndInfo = document.querySelector('.skill-icons--back-end')
const showMore = document.querySelectorAll('.project-wrapper__button')
// Get the buttons and list items
const project0Button = document.getElementById('project-0-button');
const project1Button = document.getElementById('project-1-button');
const project0ListItems = document.querySelectorAll('.list-0');
const project1ListItems = document.querySelectorAll('.list-1');

const cld = new Cloudinary({cloud: {cloudName: 'dipnvqfev'}});

// data
let project0 =false;
let project1 =false;


// Functions

// Toggeling skill icons
function handleMouseOver(e) {
   if(e === 'tools') {
        toolsInfo.style.display = 'grid';
        backEndInfo.style.display = 'none';
        frontEndInfo.style.display = 'none';
    }   else if (e === 'back-end') {
        backEndInfo.style.display = 'grid';
        toolsInfo.style.display = 'none';
        frontEndInfo.style.display = 'none';
    }   else if(e === 'front-end') {
        toolsInfo.style.display = 'none';
        backEndInfo.style.display = 'none';
        frontEndInfo.style.display = 'grid';
    }
}

// Toggeling skill icons
function handleMouseOut(e) {
    if(e === 'tools') {
         toolsInfo.style.display = 'none';
         frontEndInfo.style.display = 'grid';
     }   else if (e === 'back-end') {
         backEndInfo.style.display = 'none';
         toolsInfo.style.display = 'none';
         frontEndInfo.style.display = 'grid';
     }
}

// Toggeling extra information in project section
function toggleListItems(buttonElement, listItems) {
    const button = document.getElementById(buttonElement);
    let project = false;

    button.addEventListener('click', function () {
      project = !project;
      button.innerHTML = project ? 'Zie minder' : 'Bekijk meer...';

      // Toggle the visibility of the list items
      listItems.forEach(item => {
        item.style.display = project ? 'block' : 'none';
      });
    });
  }

// Toggle extra information in the projects section
 toggleListItems('project-0-button', project0ListItems);
 toggleListItems('project-1-button', project1ListItems);

// Event listiners

// mouse events
skillsFrondEnd.addEventListener('mouseover',() => handleMouseOver('front-end'));
skillsFrondEnd.addEventListener('mouseout',() => handleMouseOut('front-end'));
skillsTools.addEventListener('mouseover',() => handleMouseOver('tools'));
skillsTools.addEventListener('mouseout',() => handleMouseOut('tools'));
skillsBackEnd.addEventListener('mouseover',() => handleMouseOver('back-end'));
skillsBackEnd.addEventListener('mouseout',() => handleMouseOut('back-end'));

// Touch events
skillsFrondEnd.addEventListener('touchstart',() => handleMouseOver('front-end'));
skillsTools.addEventListener('touchstart',() => handleMouseOver('tools'));
skillsBackEnd.addEventListener('touchstart',() => handleMouseOver('back-end'));

// Click events
project0Button.addEventListener('click', toggleListItems);
project1Button.addEventListener('click', toggleListItems);
