
const navMenu = document.querySelector('.links');
const toggleBtn = document.querySelector('.toggle-btn');


const toggleNavMenu = () => {
  navMenu.classList.toggle('active')
}

toggleBtn.addEventListener("click", toggleNavMenu)
